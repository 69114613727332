import { Component, inject } from '@angular/core';
import { GeneralLayoutComponent } from '../layouts/styles/general-layout/general-layout.component';
import { TechpreNatsService } from '@services/techpre-nats.service';
import { AuthService } from '@services/auth.service';
import { ToastService } from '@services/toast.service';
import { Router } from '@angular/router';
import { LoginService } from '@services/login.service';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'tech-main',
  standalone: true,
  imports: [GeneralLayoutComponent],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
})
export class MainComponent {
  private _techpreSrc = inject(TechpreNatsService);
  private _authSrc = inject(AuthService);
  private _loginSrc = inject(LoginService);
  private _notificationSrc = inject(ToastService);
  private _suscriptions: Subscription[] = [];

  private initialSubscriptions = [
    {
      key: `${this._authSrc.getInfo().organizationId}.${
        this._authSrc.getInfo().id
      }.notification`,
      cb: (msg) => {
        this._notificationSrc.addNotification(JSON.parse(msg.string()));
      },
    },
    {
      key: `${this._authSrc.getInfo().organizationId}.notification`,
      cb: (msg) => {
        this._notificationSrc.addNotification(JSON.parse(msg.string()));
      },
    },
    {
      key: `${this._authSrc.getInfo().organizationId}.${
        this._authSrc.getInfo().id
      }.kill`,
      cb: () => {
        this._loginSrc.logout();
        this._notificationSrc.addNotification({
          type: 'error',
          title: 'Cierre inesperado',
          msg: 'Por favor, comunicate con el administrador',
        });
      },
    },
    {
      key: `${this._authSrc.getInfo().organizationId}.${
        this._authSrc.getInfo().id
      }.exit`,
      cb: () => {
        this._loginSrc.logout();
        this._notificationSrc.addNotification({
          title: 'Cierre de sesión',
          msg: 'Sesión cerrada exitosamente',
        });
      },
    },
  ];

  ngOnInit(): void {
    this.initialSubscriptions.forEach(({ key, cb }) => {
      this._suscriptions.push(this._techpreSrc.subcribeTo({ key, cb }));
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this._suscriptions.forEach((sub) => sub.unsubscribe());
  }
}
