<ul class="navlist">
  @for (module of modules; track $index) {
  <li class="modules_navbar">
    <!-- <div class="navlist__item__icon">
      @switch (module.icon) { @case ('homeIcon') {
      <ng-container *ngTemplateOutlet="homeIcon"></ng-container> } @case
      ('redIcon') { <ng-container *ngTemplateOutlet="redIcon"></ng-container> }
      @case ('financeIcon') {
      <ng-container *ngTemplateOutlet="financeIcon"></ng-container> } @case
      ('resourcesIcon') {
      <ng-container *ngTemplateOutlet="resourcesIcon"></ng-container> } @case
      ('devIcon') { <ng-container *ngTemplateOutlet="devIcon"></ng-container> }
      @case ('settingsIcon') {
      <ng-container *ngTemplateOutlet="settingsIcon"></ng-container> } @default
      {
      <ng-container *ngTemplateOutlet="unknownIcon"></ng-container>
      } }
    </div>
    <p class="navlist__item__label">
      {{ module.label }}
    </p> -->
    <!-- <tech-menu-list [children]="module?.children ?? []">
      <li class="navlist__item">
        <div class="navlist__item__icon">
          @switch (module.icon) { @case ('homeIcon') {
          <ng-container *ngTemplateOutlet="homeIcon"></ng-container> } @case
          ('redIcon') {
          <ng-container *ngTemplateOutlet="redIcon"></ng-container> } @case
          ('financeIcon') {
          <ng-container *ngTemplateOutlet="financeIcon"></ng-container> } @case
          ('resourcesIcon') {
          <ng-container *ngTemplateOutlet="resourcesIcon"></ng-container> }
          @case ('devIcon') {
          <ng-container *ngTemplateOutlet="devIcon"></ng-container> } @case
          ('settingsIcon') {
          <ng-container *ngTemplateOutlet="settingsIcon"></ng-container> }
          @default {
          <ng-container *ngTemplateOutlet="unknownIcon"></ng-container>
          } }
        </div>
        <p class="navlist__item__label">
          {{ module.label }}
        </p>
      </li>
    </tech-menu-list> -->

    <div class="navlist__item">
      <div class="navlist__item__icon">
        @switch (module.icon) { @case ('homeIcon') {
        <ng-container *ngTemplateOutlet="homeIcon"></ng-container> } @case
        ('redIcon') {
        <ng-container *ngTemplateOutlet="redIcon"></ng-container> } @case
        ('financeIcon') {
        <ng-container *ngTemplateOutlet="financeIcon"></ng-container> } @case
        ('resourcesIcon') {
        <ng-container *ngTemplateOutlet="resourcesIcon"></ng-container> } @case
        ('devIcon') {
        <ng-container *ngTemplateOutlet="devIcon"></ng-container> } @case
        ('settingsIcon') {
        <ng-container *ngTemplateOutlet="settingsIcon"></ng-container> }
        @default {
        <ng-container *ngTemplateOutlet="unknownIcon"></ng-container>
        } }
      </div>
      <p class="navlist__item__label">
        {{ module.label }}
      </p>
    </div>
    <div class="navlist__sub-modules">
      @for (subModule of module?.children ?? []; track $index) {
      <a
        class="navlist__sub-modules__link"
        [routerLink]="subModule.routerLink"
        routerLinkActive="active"
      >
        {{ subModule.label }}</a
      >
      }
    </div>
  </li>
  }
</ul>

<ng-template #homeIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 0 0 2.25-2.25V6.75a2.25 2.25 0 0 0-2.25-2.25H6.75A2.25 2.25 0 0 0 4.5 6.75v10.5a2.25 2.25 0 0 0 2.25 2.25Zm.75-12h9v9h-9v-9Z"
    />
  </svg>
</ng-template>
<ng-template #redIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M9.348 14.652a3.75 3.75 0 0 1 0-5.304m5.304 0a3.75 3.75 0 0 1 0 5.304m-7.425 2.121a6.75 6.75 0 0 1 0-9.546m9.546 0a6.75 6.75 0 0 1 0 9.546M5.106 18.894c-3.808-3.807-3.808-9.98 0-13.788m13.788 0c3.808 3.807 3.808 9.98 0 13.788M12 12h.008v.008H12V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
    />
  </svg>
</ng-template>
<ng-template #financeIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
    />
  </svg>
</ng-template>
<ng-template #resourcesIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
    />
  </svg>
</ng-template>
<ng-template #devIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
    />
  </svg>
</ng-template>
<ng-template #settingsIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 0 1 1.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.559.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.894.149c-.424.07-.764.383-.929.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 0 1-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.398.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 0 1-.12-1.45l.527-.737c.25-.35.272-.806.108-1.204-.165-.397-.506-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.108-1.204l-.526-.738a1.125 1.125 0 0 1 .12-1.45l.773-.773a1.125 1.125 0 0 1 1.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894Z"
    />
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    />
  </svg>
</ng-template>
<ng-template #unknownIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M9.75 3.104v5.714a2.25 2.25 0 0 1-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 0 1 4.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0 1 12 15a9.065 9.065 0 0 0-6.23-.693L5 14.5m14.8.8 1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0 1 12 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5"
    />
  </svg>
</ng-template>
